<template>
  <div class="xflex xflex-col">
    <v-expand-transition>
      <small class="xfont-semibold xpy-[5px] xtext-[#2E2E2E]" v-if="expand">
        You can add @name and it will be translated into user's username. e.g
        Hello @name!
      </small>
    </v-expand-transition>
    <div class="xflex xflex-row xgap-1 xjustify-between xitems-center">
      <v-text-field
        v-bind="$attrs"
        hide-details="auto"
        outlined
        class="xflex-1"
        ref="textfield"
        dense
        @click:prepend-inner="expand = !expand"
        :prepend-inner-icon="
          expand ? `mdi-information-off-outline` : `mdi-information-outline`
        "
        :placeholder="placeholder"
        color="primary"
        v-model="text"
      >
        <!-- <template v-slot:append>
          <v-menu
            :close-on-content-click="false"
            :nudge-top="5"
            close-on-click
            top
            offset-y
          >
            <template v-slot:activator="{ on, attrs }">
              <v-icon color="primary" v-bind="attrs" v-on="on">
                mdi-sticker-emoji
              </v-icon>
            </template>
            <Picker :data="emojiIndex" @select="addEmoji" />
          </v-menu>
        </template> -->
      </v-text-field>
      <slot name="default"></slot>
    </div>
  </div>
</template>

<script>
import data from "emoji-mart-vue-fast/data/all.json";
import { Picker, EmojiIndex } from "emoji-mart-vue-fast";
import "emoji-mart-vue-fast/css/emoji-mart.css";

export default {
  components: { Picker },
  inheritAttrs: false,
  computed: {
    emojiIndex() {
      return new EmojiIndex(data);
    },
  },
  props: {
    value: { type: String },
    placeholder: { type: String, default: "Enter message" },
  },
  data() {
    return {
      expand: true,
      text: null,
      menu: false,
    };
  },
  watch: {
    value: {
      handler: function (val) {
        this.text = val;
      },
      immediate: true,
    },
    text: {
      handler: function (val) {
        this.$emit("input", val);
      },
      immediate: true,
    },
  },
  methods: {
    insertIntoCursorPosition(insertText) {
      const textfield = this.$refs.textfield.$refs.input;
      const sentence = textfield.value;
      const len = sentence.length;
      let pos = textfield.selectionStart;
      if (pos === undefined) {
        pos = 0;
      }
      const before = sentence.substr(0, pos);
      const after = sentence.substr(pos, len) || "";

      this.text = before + insertText + after;
      this.$nextTick().then(() => {
        textfield.selectionStart = pos + insertText.length;
        textfield.selectionEnd = pos + insertText.length;
        textfield.focus();
      });
    },
    addEmoji(emoji) {
      if (!this.text) this.text = emoji.native;
      else this.insertIntoCursorPosition(emoji.native);
    },
  },
};
</script>

<style scoped></style>
